import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, Link, graphql } from "gatsby"
import { Linkedin, GitHub, Gitlab, Moon } from "react-feather"
import scrollTo from "gatsby-plugin-smoothscroll"

import Nav from "./nav"

import "fontsource-league-spartan" // Defaults to weight 400 with all styles included.
import "fontsource-league-mono-narrow" // Defaults to weight 400 with all styles included.

import "normalize.css"
import "../css/theme.css"
import "../css/styles.css"

// scrollToFromLink: use for scrollTo from a link
const scrollToFromLink = target => {
  return e => {
    e.preventDefault()
    scrollTo(target)
  }
}

/* menu visibility toggle */

const SidebarLeft = () => (
  <div className="sidebar left-bar">
    <div className="sidebar-feature" />
    <Link to="/" className="logo grow">
      <Moon />
    </Link>
  </div>
)

const SidebarRight = ({ children }) => (
  <div className="sidebar right-bar">
    <div className="sidebar-feature">
      <a
        onClick={scrollToFromLink("#contact")}
        aria-label="scroll to contact form"
        href="/"
      >
        Contact Me
      </a>
    </div>
    {children}
  </div>
)
SidebarRight.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              tagline
              github
              gitlab
              linkedin
            }
          }
        }
      `}
      render={data => (
        <div id="outer-container">
          <Nav>
            <ul>
              <li>
                <a
                  onClick={scrollToFromLink("#hero")}
                  aria-label="scroll to top"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  onClick={scrollToFromLink("#about")}
                  aria-label="scroll to about"
                  href="/"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  onClick={scrollToFromLink("#contact")}
                  aria-label="scroll to contact form"
                  href="/"
                >
                  Contact Me
                </a>
              </li>
            </ul>
          </Nav>
          <div id="container" className="container">
            {" "}
            <SidebarLeft />
            <main className="content">{children}</main>
            <SidebarRight>
              <a
                href={
                  "https://www.linkedin.com/in/" +
                  data.site.siteMetadata.linkedin
                }
                className="logo grow"
              >
                <Linkedin />
              </a>
              <a
                href={"https://github.com/" + data.site.siteMetadata.github}
                className="logo grow"
              >
                <GitHub />
              </a>
              <a
                href={"https://gitlab.com/" + data.site.siteMetadata.gitlab}
                className="logo grow"
              >
                <Gitlab />
              </a>
            </SidebarRight>
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
