import React from "react"
import PropTypes from "prop-types"
import { Menu as MenuIcon, Home as HomeIcon } from "react-feather"
import { push as Menu } from "react-burger-menu"
import { Link } from "gatsby"

import "../css/nav.css"

const HomeButton = () => (
  <Link to="/" className="home-button" aria-label="home button">
    <HomeIcon />
  </Link>
)

const Nav = ({ children }) => {
  return (
    <>
      <HomeButton />
      <Menu
        right
        outerContainerId="outer-container"
        pageWrapId="container"
        customBurgerIcon={<MenuIcon />}
      >
        {children}
      </Menu>
    </>
  )
}
Nav.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
export default Nav
